import React, { useEffect, memo, Fragment, useState } from "react";
import { Card, Dropdown, Row, Col, Button } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";


//circular
import Circularprogressbar from "../../components/circularprogressbar.js";


// AOS
import AOS from "aos";
import "../../../node_modules/aos/dist/aos";
import "../../../node_modules/aos/dist/aos.css";
//apexcharts
import Chart from "react-apexcharts";
import ApexCharts from "react-apexcharts";




//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";


// Import Swiper styles
import "swiper/swiper-bundle.min.css";
// import 'swiper/components/navigation/navigation.scss';


//progressbar
// import Progress from "../../components/progress.js";
//img
// import shapes1 from "../../assets/images/shapes/01.png";
// import shapes2 from "../../assets/images/shapes/02.png";
// import shapes3 from "../../assets/images/shapes/03.png";
// import shapes4 from "../../assets/images/shapes/04.png";
// import shapes5 from "../../assets/images/shapes/05.png";


//Count-up
import CountUp from "react-countup";


// Redux Selector / Action
import { useSelector } from "react-redux";


// Import selectors & action from setting store
import * as SettingSelector from "../../store/setting/selectors";


// install Swiper modules
SwiperCore.use([Navigation]);


const Index = memo((props) => {
  let history = useNavigate()
  useSelector(SettingSelector.theme_color);


  let token = sessionStorage.getItem("revizeTrainingToken");
  const userRole = sessionStorage.getItem("userRole");
  const statsApi = `${process.env.REACT_APP_SERVER_URL}/api/stats`;
  const [siteCount, setSiteCount] = useState(0);
  const [trainedDocCount, setTrainedDocCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [fstData, setFSTData] = useState([]);
  const [siteId, setSiteId] = useState("");
  const [siteName, setSiteName] = useState("");
  const [siteKey, setSiteKey] = useState("");
  const userApi = `${process.env.REACT_APP_SERVER_URL}/api/users/me`;


  useEffect(() => {
    // Function to call on page load
    if (!token) {
      history('/auth/sign-in');
    }


    const requestOptions = {
      method: "GET",
      headers: { "accept": "application/json", "Authorization": "Bearer " + token },
    };


    fetch(statsApi, requestOptions)
      .then(function (response) {
        return response.json();
      }).then(function (data) {
        // console.log('data', data)
        if (!data) {
          return;
        }
        if (userRole === "ADMIN" || userRole === "SITEADMIN") {
          setUserCount(data['user_count']);
        }
        if (userRole !== "ADMIN") {
          setSiteId(data['site_id']);
          setSiteName(data['site_name']);
          setSiteKey(data['site_key']);
        }
        setSiteCount(data['site_count']);
        setTrainedDocCount(data['trained_doc_count']);
        setFSTData(data['fst_requests'])
      }).catch(err => console.log("error", err));


  }, [statsApi, history, token, userRole]);


  const getVariableColor = () => {
    let prefix =
      getComputedStyle(document.body).getPropertyValue("--prefix") || "bs-";
    if (prefix) {
      prefix = prefix.trim();
    }
    const color1 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary`
    );
    const color2 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}info`
    );
    const color3 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary-tint-20`
    );
    const color4 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}warning`
    );
    return {
      primary: color1.trim(),
      info: color2.trim(),
      warning: color4.trim(),
      primary_light: color3.trim(),
    };
  };
  const variableColors = getVariableColor();


  const colors = [variableColors.primary, variableColors.info];
  useEffect(() => {
    return () => colors;
  });


  useEffect(() => {
    AOS.init({
      startEvent: "DOMContentLoaded",
      disable: function () {
        var maxWidth = 996;
        return window.innerWidth < maxWidth;
      },
      throttleDelay: 10,
      once: true,
      duration: 700,
      offset: 10,
    });
  });


  const [userAnalyticsCategories, setUserAnalyticsCategories] = useState([])


  const chart1 = {
    options: {
      chart: {
        fontFamily:
          '"Inter", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: false,
        },
      },
      colors: colors,
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      yaxis: {
        show: true,
        labels: {
          show: true,
          minWidth: 19,
          maxWidth: 19,
          style: {
            colors: "#8A92A6",
          },
          offsetX: -5,
        },
      },
      legend: {
        show: false,
      },
      xaxis: {
        labels: {
          minHeight: 22,
          maxHeight: 22,
          show: true,
          style: {
            colors: "#8A92A6",
          },
        },
        lines: {
          show: false, //or just here to disable only x axis grids
        },
        categories: userAnalyticsCategories,
      },
      grid: {
        show: false,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "vertical",
          shadeIntensity: 0,
          gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
          inverseColors: true,
          opacityFrom: 0.4,
          opacityTo: 0.1,
          stops: [0, 50, 80],
          colors: colors,
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    series: [
      {
        name: "total",
        data: [94, 80, 94, 80, 94, 80, 94],
      },
      {
        name: "pipline",
        data: [72, 60, 84, 60, 74, 60, 78],
      },
    ],
  };


  // // chart2
  // const chart2 = {
  //   options: {
  //     colors: colors,
  //     plotOptions: {
  //       radialBar: {
  //         hollow: {
  //           margin: 10,
  //           size: "50%",
  //         },
  //         track: {
  //           margin: 10,
  //           strokeWidth: "50%",
  //         },
  //         dataLabels: {
  //           show: false,
  //         },
  //       },
  //     },
  //     labels: ["Apples", "Oranges"],
  //   },
  //   series: [55, 75],
  // };
  // const chart3 = {
  //   options: {
  //     chart: {
  //       stacked: true,
  //       toolbar: {
  //         show: false,
  //       },
  //     },
  //     colors: colors,
  //     plotOptions: {
  //       bar: {
  //         horizontal: false,
  //         columnWidth: "28%",
  //         endingShape: "rounded",
  //         borderRadius: 5,
  //       },
  //     },
  //     legend: {
  //       show: false,
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     stroke: {
  //       show: true,
  //       width: 2,
  //       colors: ["transparent"],
  //     },
  //     xaxis: {
  //       categories: ["S", "M", "T", "W", "T", "F", "S", "M", "T", "W", "B", "C"],
  //       labels: {
  //         minHeight: 20,
  //         maxHeight: 20,
  //         style: {
  //           colors: "#8A92A6",
  //         },
  //       },
  //     },
  //     yaxis: {
  //       title: {
  //         text: "",
  //       },
  //       labels: {
  //         minWidth: 19,
  //         maxWidth: 19,
  //         style: {
  //           colors: "#8A92A6",
  //         },
  //       },
  //     },
  //     fill: {
  //       opacity: 1,
  //     },
  //     tooltip: {
  //       y: {
  //         formatter: function (val) {
  //           return "$ " + val + " thousands";
  //         },
  //       },
  //     },
  //   },
  //   series: [
  //     {
  //       name: "Successful deals",
  //       data: [30, 50, 35, 60, 40, 60, 60, 30, 50, 35, 55, 75],
  //     },
  //     {
  //       name: "Failed deals",
  //       data: [40, 50, 55, 50, 30, 80, 30, 40, 50, 55, 35, 25],
  //     },
  //   ],
  // };
  const [timePeriod, setTimePeriod] = useState('Daily'); // State for the time dropdown
  const [category, setCategory] = useState('All'); // State for the category dropdown
  const [websiteNameList, setWebsiteNameList] = useState([])
  const [websiteNameListwithIds, setWebsiteNameListWIthIds] = useState({ "All": "All" })
  const [selectedWebsite, setSelectedWebsite] = useState("All")
  const [websiteWordCount, setWebsiteWordCount] = useState(0)
  const [responseWordCount, setResponseWordCount] = useState(0)
  const [userAnalyticsTimePeriod, setUserAnalyticsTimePeriod] = useState(0)


  const dummyGraphData = {
    date: [
      "2025-01-01", "2025-01-02", "2025-01-03", "2025-01-04", "2025-01-05", 
      "2025-01-06", "2025-01-07", "2025-01-08", "2025-01-09", "2025-01-10", 
      "2025-01-11", "2025-01-12", "2025-01-13", "2025-01-14", "2025-01-15", 
      "2025-01-16", "2025-01-17", "2025-01-18", "2025-01-19", "2025-01-20", 
      "2025-01-21", "2025-01-22", "2025-01-23", "2025-01-24", "2025-01-25", 
      "2025-01-26", "2025-01-27", "2025-01-28", "2025-01-29", "2025-01-30"
    ],
    query_word_count: [
      100, 200, 150, 300, 250, 120, 180, 220, 170, 200, 
      140, 240, 210, 230, 190, 160, 250, 270, 200, 180, 
      220, 260, 300, 240, 280, 210, 190, 230, 200, 250
    ],
    response_word_count: [
      900, 1800, 1400, 2800, 24000, 1100, 17000, 2000, 16000, 1900, 
      1500, 2300, 2000, 2200, 18000, 1600, 2400, 2600, 19000, 1900, 
      2500, 2500, 2900, 23000, 2700, 2000, 1800, 2200, 19000, 2400
    ],
  };


  const dummyPromptData = {
    date: [
      "2025-01-01", "2025-01-02", "2025-01-03", "2025-01-04", "2025-01-05", 
      "2025-01-06", "2025-01-07", "2025-01-08", "2025-01-09", "2025-01-10", 
      "2025-01-11", "2025-01-12", "2025-01-13", "2025-01-14", "2025-01-15", 
      "2025-01-16", "2025-01-17", "2025-01-18", "2025-01-19", "2025-01-20", 
      "2025-01-21", "2025-01-22", "2025-01-23", "2025-01-24", "2025-01-25", 
      "2025-01-26", "2025-01-27", "2025-01-28", "2025-01-29", "2025-01-30"
    ],
    total_count: [
      10, 15, 12, 20, 18, 14, 16, 22, 19, 17, 
      15, 21, 23, 18, 20, 16, 19, 22, 24, 21, 
      17, 23, 25, 20, 22, 18, 16, 19, 21, 24
    ],
  };


  const dummyRegionalAnalytics = {
    dates: [
      "2025-01-01", "2025-01-02", "2025-01-03", "2025-01-04", "2025-01-05", 
      "2025-01-06", "2025-01-07", "2025-01-08", "2025-01-09", "2025-01-10", 
      "2025-01-11", "2025-01-12", "2025-01-13", "2025-01-14", "2025-01-15", 
      "2025-01-16", "2025-01-17", "2025-01-18", "2025-01-19", "2025-01-20", 
      "2025-01-21", "2025-01-22", "2025-01-23", "2025-01-24", "2025-01-25", 
      "2025-01-26", "2025-01-27", "2025-01-28", "2025-01-29", "2025-01-30"
    ],
    series: [
      { name: "USA", data: [30, 40, 35, 50, 45, 38, 42, 48, 36, 40, 44, 50, 55, 47, 45, 50, 53, 60, 58, 55, 50, 60, 62, 54, 56, 52, 50, 58, 57, 60] },
      { name: "India", data: [20, 30, 25, 35, 28, 22, 26, 32, 24, 30, 28, 34, 32, 30, 35, 38, 40, 36, 35, 30, 28, 38, 40, 34, 36, 32, 30, 36, 35, 38] },
      { name: "Germany", data: [10, 15, 12, 18, 16, 14, 13, 20, 17, 16, 15, 18, 17, 19, 18, 16, 20, 22, 21, 19, 18, 22, 24, 20, 22, 18, 16, 20, 19, 22] },
    ],
  };




  useEffect(() => {
    const fetchWebsites = async () => {
      try {
        const response = await fetch("https://0.0.0.0:8000/api/analytics/website_list");
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        //console.log("data", data)
        for (const record of data) {
          websiteNameList.push(record['name'])
          websiteNameListwithIds[record['name']] = record['id'];
        }
      } catch (error) {
        console.error("Error fetching website list:", error);
      }
    };


    fetchWebsites();
  }, []); // Runs on page load


  const [graphData, setGraphData] = useState({ date: [], query_word_count: [], response_word_count: [] });




  // useEffect(() => {
  //   const fetchWebsiteWordCount = async () => {
  //     console.log('selectedWebsite', websiteNameListwithIds[selectedWebsite]);
  //     try {
  //       const response = await fetch(
  //         `https://0.0.0.0:8000/api/analytics/website_word_count?website=${websiteNameListwithIds[selectedWebsite]}&timePeriod=${timePeriod}`
  //       );
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }
  //       const data = await response.json();
  //       console.log('data', data);
  //       setGraphData(data);
  //     } catch (error) {
  //       console.error('Error fetching website word count:', error);
  //     }
  //   };


  //   fetchWebsiteWordCount();
  // }, [selectedWebsite, timePeriod]); // Runs on timePeriod or selectedWebsite change


  // ApexCharts options and series
  const chartOptions = {
    chart: {
      type: "line",
      zoom: { enabled: false },
    },
    xaxis: {
      categories: dummyGraphData.date,
      title: { text: "Date" },
    },
    yaxis: {
      title: { text: "Word Count" },
    },
    dataLabels: { enabled: false },
    stroke: { curve: "smooth" },
    tooltip: { x: { format: "yyyy-MM-dd" } },
    colors: ["#546E7A", "#26A69A"], // Custom colors for the lines
    legend: { position: "top" },
  };


  const chartSeries = [
    {
      name: "Query Word Count",
      data: dummyGraphData.query_word_count ,
    },
    {
      name: "Response Word Count",
      data: dummyGraphData.response_word_count,
    },
  ];


  const handleTimePeriodChange = (period) => {
    setUserAnalyticsTimePeriod(period);
    //console.log(`Selected time period: ${period}`);
  };


  useEffect(() => {
    if (userAnalyticsTimePeriod == "This Week") {
      setUserAnalyticsCategories(["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"])
    } else if (userAnalyticsTimePeriod == "This Month") {
      setUserAnalyticsCategories(["Week 1", "Week 2", "Week 3", "Week 4"])
    } else if (userAnalyticsTimePeriod == "This Year") {
      setUserAnalyticsCategories(["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"])
    }
  }, [userAnalyticsTimePeriod])


  useEffect(() => {
    if (!token) {
      history('/auth/sign-in');
    }
    if (token) {
      // eslint-disable-next-line
      const requestOptions = {
        method: "GET",
        headers: { "accept": "application/json", "Authorization": "Bearer " + token },
      };


      fetch(userApi, requestOptions)
        .then(function (response) {
          return response.json();
        }).then(function (data) {
          if (data.is_active == false) {
            sessionStorage.removeItem("revizeTrainingToken");
            sessionStorage.removeItem("loggedInUserId");
            sessionStorage.removeItem("userRole");
            sessionStorage.removeItem("firstName");
            window.location.href = '/auth/sign-in';
          }
        }).catch(err => {
          console.log("error", err);


          // Clear session storage and redirect to sign-in
          sessionStorage.removeItem("revizeTrainingToken");
          sessionStorage.removeItem("loggedInUserId");
          sessionStorage.removeItem("userRole");
          sessionStorage.removeItem("firstName");
          window.location.href = '/auth/sign-in';
        });
    }


  }, [])




  const [selectedWebsiteForPromptCount, setSelectedWebsiteForPromptCount] = useState("All");
  const [dateFilterForPromptCount, setDateFilterForPromptCount] = useState("Daily");
  const [chartDataForPromptCount, setChartDataForPromptCount] = useState({ date: [], total_count: [] });


  // useEffect(() => {
  //   const fetchPromptCount = async () => {
  //     try {
  //       const response = await fetch(
  //         `https://0.0.0.0:8000/api/analytics/website_prompt_count?website=${websiteNameListwithIds[selectedWebsiteForPromptCount]}&timePeriod=${dateFilterForPromptCount}`
  //       );
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }
  //       const data = await response.json();
  //       console.log("promptData", data)
  //       setChartDataForPromptCount(data); // Assuming the API returns data in the format { dates: [...], counts: [...] }
  //     } catch (error) {
  //       console.error("Error fetching prompt count:", error);
  //     }
  //   };


  //   fetchPromptCount();
  // }, [selectedWebsiteForPromptCount, dateFilterForPromptCount]);


  // ApexCharts options and series
  const chartOptionsForPromptCount = {
    chart: {
      type: "bar",
      zoom: { enabled: false },
    },
    xaxis: {
      categories: dummyPromptData.date, // Fallback to an empty array
      title: { text: "Date" },
    },
    yaxis: {
      title: { text: "Prompt Count" },
    },
    dataLabels: { enabled: false },
    tooltip: { x: { format: "yyyy-MM-dd" } },
    colors: ["#1E88E5"],
    legend: { show: false },
  };


  const chartSeriesForPromptCount = [
    {
      name: "Prompt Count",
      data: dummyPromptData.total_count, // Fallback to an empty array
    },
  ];




  const [selectedWebsiteForTopSearchedPrompts, setSelectedWebsiteForTopSearchedPrompts] = useState("Select Website");
  const [topSearchedPrompts, setTopSearchedPrompts] = useState([
    "What is machine learning?",
    "What is artificial intelligence?",
    "What is the name of the mayor?",
    "List out some news from the article.",
    "Who is the organisation head?"
  ]);
  const [fetchingPrompts,setFetchingPrompts] = useState(false);


  // useEffect(() => {
  //   const fetchTopSearchedPrompts = async () => {
  //     try {
  //       if (selectedWebsiteForTopSearchedPrompts == "Select Website") {
  //         setTopSearchedPrompts([]);
  //         return
  //       }
  //       setFetchingPrompts(true)
  //       const response = await fetch(
  //         `https://0.0.0.0:8000/api/analytics/get_most_searched_unique_prompts?website=${websiteNameListwithIds[selectedWebsiteForTopSearchedPrompts]}`
  //       );
  //       setFetchingPrompts(false)
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }
  //       const data = await response.json();
  //       setTopSearchedPrompts(data); // Assuming the API returns data in the format { dates: [...], counts: [...] }
  //     } catch (error) {
  //       console.error("Error fetching prompt count:", error);
  //     }
  //   };


  //   fetchTopSearchedPrompts();
  // }, [selectedWebsiteForTopSearchedPrompts]);




  const [regionalAnalyticsChartOptions, setRegionalAnalyticsChartOptions] = useState({});
  const [regionalAnalyticsChartSeries, setRegionalAnalyticsChartSeries] = useState([]);
  const [dateFilterForRegionalAnalytics, setDateFilterForRegionalAnalytics] = useState("Daily");


  // Generate different shades of the base blue color
  const generateBlueShades = (baseColor, numShades) => {
    // Convert hex to RGB
    const r = parseInt(baseColor.slice(1, 3), 16);
    const g = parseInt(baseColor.slice(3, 5), 16);
    const b = parseInt(baseColor.slice(5, 7), 16);


    // Convert RGB to HSL
    const max = Math.max(r, g, b) / 255;
    const min = Math.min(r, g, b) / 255;
    const l = (max + min) / 2;


    const d = max - min;
    const s = d === 0 ? 0 : d / (1 - Math.abs(2 * l - 1));


    let h;
    if (d === 0) h = 0;
    else if (max === r / 255) h = 60 * ((g - b) / (d * 255) + (g < b ? 6 : 0));
    else if (max === g / 255) h = 60 * ((b - r) / (d * 255) + 2);
    else h = 60 * ((r - g) / (d * 255) + 4);


    // Generate shades by varying both lightness and saturation for better distinction
    const shades = ["#1E88E5"]; // Start with the base color
    const lightnessStep = 0.25 / Math.ceil(numShades / 2); // Adjusted step for more variation
    const saturationStep = 0.3 / Math.ceil(numShades / 2);


    for (let i = 1; i < numShades; i++) {
      const isEven = i % 2 === 0;
      const step = Math.ceil(i / 2);
      
      // Alternate between lighter and darker shades
      const newLightness = isEven
        ? Math.min(0.9, l + (step * lightnessStep))
        : Math.max(0.2, l - (step * lightnessStep));
      
      // Vary saturation slightly for better distinction
      const newSaturation = Math.max(0.3, s - (step * saturationStep));
      
      shades.push(`hsl(${h}, ${newSaturation * 100}%, ${newLightness * 100}%)`);
    }


    return shades;
  };




  // useEffect(() => {
  //   const fetchAnalyticsData = async () => {
  //     try {
  //       const response = await fetch(`https://0.0.0.0:8000/api/analytics/get_regional_analytics?date_range=${dateFilterForRegionalAnalytics}`);
  //       const data = await response.json();
  
  //       // Extract dates and all unique countries from the new data format
  //       const dates = data.map((item) => item.date);
  //       const allCountries = [
  //         ...new Set(
  //           data.flatMap((item) => item.data.map((entry) => entry.country))
  //         ),
  //       ];
  
  
  //       const countryColors = generateBlueShades("#1E88E5", allCountries.length);
  
  //       // Prepare series data
  //       const series = allCountries.map((country, index) => ({
  //         name: country,
  //         data: dates.map((date) => {
  //           const dateEntry = data.find((item) => item.date === date);
  //           if (dateEntry) {
  //             const countryEntry = dateEntry.data.find(
  //               (entry) => entry.country === country
  //             );
  //             return countryEntry ? countryEntry.activeUsers : 0;
  //           }
  //           return 0;
  //         }),
  //         color: countryColors[index], // Assign a unique color
  //       }));
  
  //       setRegionalAnalyticsChartOptions({
  //         chart: {
  //           type: "bar",
  //           stacked: true,
  //         },
  //         xaxis: {
  //           categories: dates,
  //           title: { text: "Dates" },
  //         },
  //         yaxis: {
  //           title: { text: "Active Users" },
  //         },
  //         colors: countryColors,
  //         tooltip: {
  //           shared: true,
  //           intersect: false,
  //         },
  //       });
  
  //       setRegionalAnalyticsChartSeries(series);
  //     } catch (error) {
  //       console.error("Error fetching analytics data:", error);
  //     }
  //   };
  
  //   fetchAnalyticsData();
  // }, [dateFilterForRegionalAnalytics]);
  
  const regionalChartOptions = {
    chart: { type: "bar", stacked: true },
    xaxis: {
      categories: dummyRegionalAnalytics.dates,
      title: { text: "Dates" },
    },
    yaxis: {
      title: { text: "Active Users" },
    },
    colors: ["#1E88E5","#64B5F9","#90CAF9"], // Updated lighter shades
    tooltip: { shared: true, intersect: false },
};




  const regionalChartSeries = dummyRegionalAnalytics.series;


  // const names = ['Alice', 'Bob', 'Charlie', 'David'];
  return (
    <Fragment>
      {token !== undefined && (
        <Row>
          <Col md="12" lg="12">
            <Row className="row-cols-1">
              <div className="overflow-hidden d-slider1 " data-aos="fade-up" data-aos-delay="800">
                <Swiper
                  className="p-0 m-0 mb-2 list-inline "
                  slidesPerView={5}
                  spaceBetween={32}
                  navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  breakpoints={{
                    320: { slidesPerView: 1 },
                    550: { slidesPerView: 2 },
                    991: { slidesPerView: 3 },
                    1400: { slidesPerView: 3 },
                    1500: { slidesPerView: 4 },
                    1920: { slidesPerView: 4 },
                    2040: { slidesPerView: 7 },
                    2440: { slidesPerView: 8 }
                  }}


                >
                  {(userRole === "ADMIN" || userRole === "SITEADMIN") &&
                    <SwiperSlide className="card card-slide" >
                      <div className="card-body">
                        <div className="progress-widget">
                          <Circularprogressbar
                            stroke={variableColors.primary}
                            width="60px"
                            height="60px"
                            Linecap="rounded"
                            trailstroke="#ddd"
                            strokewidth="4px"
                            style={{ width: 60, height: 60 }}
                            value={20}
                            id="circle-progress-01"
                          >
                            <svg
                              className=""
                              width="24"
                              height="24px"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="currentColor"
                                d="M5,17.59L15.59,7H9V5H19V15H17V8.41L6.41,19L5,17.59Z"
                              />
                            </svg>
                          </Circularprogressbar>
                          <div className="progress-detail">
                            <p className="mb-2">Total User</p>
                            <h4 className="counter">
                              <CountUp start={0} end={userCount} duration={3} />
                            </h4>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  }
                  <SwiperSlide className=" card card-slide">
                    <div className="card-body">
                      <div className="progress-widget">
                        <Circularprogressbar
                          stroke={variableColors.info}
                          width="60px"
                          height="60px"
                          trailstroke="#ddd"
                          strokewidth="4px"
                          Linecap="rounded"
                          style={{ width: 60, height: 60 }}
                          value={60}
                          id="circle-progress-02"
                        >
                          <svg
                            className=""
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M19,6.41L17.59,5L7,15.59V9H5V19H15V17H8.41L19,6.41Z"
                            />
                          </svg>
                        </Circularprogressbar>
                        <div className="progress-detail">
                          {userRole === "ADMIN" ?
                            <p className="mb-2">Total Client</p>
                            :
                            <p className="mb-2">Total Website</p>
                          }
                          <h4 className="counter">
                            <CountUp start={0} end={siteCount} duration={3} />
                          </h4>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className=" card card-slide">
                    {(userRole !== "ADMIN") &&
                      <Link className="btn" data-bs-toggle="tooltip" title="Total Trained Document"
                        to="/site/docs"
                        state={{ siteId: siteId, siteName: siteName, siteKey: siteKey }}
                        style={{}}
                      >
                        <div className="card-body">
                          <div className="progress-widget">
                            <Circularprogressbar
                              stroke={variableColors.primary}
                              width="60px"
                              height="60px"
                              trailstroke="#ddd"
                              strokewidth="4px"
                              Linecap="rounded"
                              style={{ width: 60, height: 60 }}
                              value={70}
                              id="circle-progress-03"
                            >
                              <svg className="" width="24" viewBox="0 0 24 24">
                                <path
                                  fill="currentColor"
                                  d="M19,6.41L17.59,5L7,15.59V9H5V19H15V17H8.41L19,6.41Z"
                                />
                              </svg>
                            </Circularprogressbar>
                            <div className="progress-detail">
                              <p className="mb-2">Total Trained Documents</p>
                              <h4 className="counter">
                                <CountUp start={0} end={trainedDocCount} duration={3} />
                              </h4>
                            </div>
                          </div>
                        </div>
                      </Link>}
                    {(userRole === "ADMIN") &&
                      <div className="card-body">
                        <div className="progress-widget">
                          <Circularprogressbar
                            stroke={variableColors.primary}
                            width="60px"
                            height="60px"
                            trailstroke="#ddd"
                            strokewidth="4px"
                            Linecap="rounded"
                            style={{ width: 60, height: 60 }}
                            value={70}
                            id="circle-progress-03"
                          >
                            <svg className="" width="24" viewBox="0 0 24 24">
                              <path
                                fill="currentColor"
                                d="M19,6.41L17.59,5L7,15.59V9H5V19H15V17H8.41L19,6.41Z"
                              />
                            </svg>
                          </Circularprogressbar>
                          <div className="progress-detail">
                            <p className="mb-2">Total Trained Documents</p>
                            <h4 className="counter">
                              <CountUp start={0} end={trainedDocCount} duration={3} />
                            </h4>
                          </div>
                        </div>
                      </div>
                    }
                  </SwiperSlide>
                  {/* <SwiperSlide className=" card card-slide">
                  <div className="card-body">
                    <div className="progress-widget">
                      <Circularprogressbar
                        stroke={variableColors.info}
                        width="60px"
                        height="60px"
                        trailstroke="#ddd"
                        strokewidth="4px"
                        Linecap="rounded"
                        style={{ width: 60, height: 60 }}
                        value={60}
                        id="circle-progress-04"
                      >
                        <svg
                          className=""
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M5,17.59L15.59,7H9V5H19V15H17V8.41L6.41,19L5,17.59Z"
                          />
                        </svg>
                      </Circularprogressbar>
                      <div className="progress-detail">
                        <p className="mb-2">Revenue</p>
                        <h4 className="counter">
                          $<CountUp start={212} end={742} duration={3} />K
                        </h4>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className=" card card-slide">
                  <div className="card-body">
                    <div className="progress-widget">
                      <Circularprogressbar
                        stroke={variableColors.primary}
                        width="60px"
                        height="60px"
                        trailstroke="#ddd"
                        strokewidth="4px"
                        Linecap="rounded"
                        style={{ width: 60, height: 60 }}
                        value={50}
                        id="circle-progress-05"
                      >
                        <svg
                          className=""
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M5,17.59L15.59,7H9V5H19V15H17V8.41L6.41,19L5,17.59Z"
                          />
                        </svg>
                      </Circularprogressbar>
                      <div className="progress-detail">
                        <p className="mb-2">Net Income</p>
                        <h4 className="counter">
                          $<CountUp start={35} end={150} duration={3} />K
                        </h4>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className=" card card-slide">
                  <div className="card-body">
                    <div className="progress-widget">
                      <Circularprogressbar
                        stroke={variableColors.info}
                        width="60px"
                        height="60px"
                        trailstroke="#ddd"
                        Linecap="rounded"
                        strokewidth="4px"
                        value={40}
                        style={{ width: 60, height: 60 }}
                        id="circle-progress-06"
                      >
                        <svg
                          className=""
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M5,17.59L15.59,7H9V5H19V15H17V8.41L6.41,19L5,17.59Z"
                          />
                        </svg>
                      </Circularprogressbar>
                      <div className="progress-detail">
                        <p className="mb-2">Today</p>
                        <h4 className="counter">
                          $<CountUp start={652} end={4600} duration={3} />
                        </h4>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className=" card card-slide">
                  <div className="card-body">
                    <div className="progress-widget">
                      <Circularprogressbar
                        stroke={colors}
                        Linecap="rounded"
                        trailstroke="#ddd"
                        strokewidth="4px"
                        width="60px"
                        height="60px"
                        value={30}
                        style={{ width: 60, height: 60 }}
                        id="circle-progress-07"
                      >
                        <svg
                          className=""
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M5,17.59L15.59,7H9V5H19V15H17V8.41L6.41,19L5,17.59Z"
                          />
                        </svg>
                      </Circularprogressbar>
                      <div className="progress-detail">
                        <p className="mb-2">Members</p>
                        <h4 className="counter">
                          <CountUp
                            start={2}
                            end={11.2}
                            duration={3}
                            decimals={1}
                          />
                          M
                        </h4>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <div className="swiper-button swiper-button-next"></div>
                <div className="swiper-button swiper-button-prev"></div> */}
                </Swiper>
              </div>
            </Row>
            <Row className="row-cols-1">
              <div className="overflow-hidden d-slider1 " data-aos="fade-up" data-aos-delay="800">
                <Swiper
                  className="p-0 m-0 mb-2 list-inline "
                  slidesPerView={5}
                  spaceBetween={32}
                  navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  breakpoints={{
                    320: { slidesPerView: 1 },
                    550: { slidesPerView: 2 },
                    991: { slidesPerView: 3 },
                    1400: { slidesPerView: 3 },
                    1500: { slidesPerView: 4 },
                    1920: { slidesPerView: 4 },
                    2040: { slidesPerView: 7 },
                    2440: { slidesPerView: 8 }
                  }}


                >
                  {userRole === "ADMIN" && (
                    <SwiperSlide className="card card-slide">
                      <Link
                        className="btn"
                        data-bs-toggle="tooltip"
                        title="Total FST Pending Records"
                        to="/site/pending_fst"
                        state={{ siteId: siteId, siteName: siteName, siteKey: siteKey }}
                        style={{}}
                      >
                        <div className="card-body">
                          <div className="progress-widget">
                            <Circularprogressbar
                              stroke={variableColors.info}
                              width="60px"
                              height="60px"
                              trailstroke="#ddd"
                              strokewidth="4px"
                              Linecap="rounded"
                              style={{ width: 60, height: 60 }}
                              value={50}
                              id="circle-progress-04"
                            >
                              <svg className="" width="24" viewBox="0 0 24 24">
                                <path
                                  fill="currentColor"
                                  d="M19,6.41L17.59,5L7,15.59V9H5V19H15V17H8.41L19,6.41Z"
                                />
                              </svg>
                            </Circularprogressbar>
                            <div className="progress-detail">
                              <p className="mb-2">Total FST Pending Records</p>
                              <h4 className="counter">
                                <CountUp start={0} end={fstData} duration={3} />
                              </h4>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  )}


                  {/* <SwiperSlide className=" card card-slide">
                  {(userRole !== "ADMIN") && 
                    <Link className="btn" data-bs-toggle="tooltip" 
                      title="Total FST Pending Records" 
                      to="/site/pending_fst" 
                      state={{siteId: siteId, siteName: siteName, siteKey: siteKey}}
                      style={{}}                  
                    >
                      <div className="card-body">
                        <div className="progress-widget">
                          <span>{userRole}</span>
                          <Circularprogressbar
                            stroke={variableColors.info}
                            width="60px"
                            height="60px"
                            trailstroke="#ddd"
                            strokewidth="4px"
                            Linecap="rounded"
                            style={{ width: 60, height: 60 }}
                            value={50}
                            id="circle-progress-04"
                          >
                            <svg className="" width="24" viewBox="0 0 24 24">
                              <path
                                fill="currentColor"
                                d="M19,6.41L17.59,5L7,15.59V9H5V19H15V17H8.41L19,6.41Z"
                              />
                            </svg>
                          </Circularprogressbar>
                          <div className="progress-detail">
                            <p className="mb-2">Total FST Pending Records</p>
                            <h4 className="counter">
                              <CountUp start={0} end={fstData} duration={3} />
                            </h4>
                          </div>
                        </div>
                      </div>
                    </Link> 
                  }


                  {(userRole === "ADMIN") && 
                    <Link className="btn" data-bs-toggle="tooltip" 
                      title="Total FST Pending Records" 
                      to="/site/pending_fst" 
                      state={{siteId: siteId, siteName: siteName, siteKey: siteKey}}
                      style={{}}
                    >
                      <div className="card-body">
                        <div className="progress-widget">
                          <Circularprogressbar
                            stroke={variableColors.info}
                            width="60px"
                            height="60px"
                            trailstroke="#ddd"
                            strokewidth="4px"
                            Linecap="rounded"
                            style={{ width: 60, height: 60 }}
                            value={50}
                            id="circle-progress-04"
                          >
                            <svg className="" width="24" viewBox="0 0 24 24">
                              <path
                                fill="currentColor"
                                d="M19,6.41L17.59,5L7,15.59V9H5V19H15V17H8.41L19,6.41Z"
                              />
                            </svg>
                          </Circularprogressbar>
                          <div className="progress-detail">
                            <p className="mb-2">Total FST Pending Records</p>
                            <h4 className="counter">
                              <CountUp start={0} end={fstData} duration={3} />
                            </h4>
                          </div>
                        </div>
                      </div>
                    </Link> 
                  }
                </SwiperSlide> */}
                </Swiper>
              </div>
            </Row>
          </Col>


          {(userRole === "ADMIN") &&    <Card style={{ width: "80%", padding: "20px", margin: "20px auto", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}>
            <Row className="align-items-center">
              <Col>
                <h5>Word Counts</h5>
              </Col>
              <Col className="text-end">
                {/* Dropdown for Time Period */}
                <Dropdown onSelect={(key) => setTimePeriod(key)} className="d-inline mx-2">
                  <Dropdown.Toggle variant="outline-primary" size="sm">
                    {timePeriod}
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ maxHeight: "200px", overflowY: "auto" }}>
                    <Dropdown.Item eventKey="Daily">Daily</Dropdown.Item>
                    <Dropdown.Item eventKey="Weekly">Weekly</Dropdown.Item>
                    <Dropdown.Item eventKey="Monthly">Monthly</Dropdown.Item>
                    <Dropdown.Item eventKey="Yearly">Yearly</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>


                {/* Dropdown for Category */}
                <Dropdown onSelect={(key) => setSelectedWebsite(key)} className="d-inline mx-2">
                  <Dropdown.Toggle variant="outline-secondary" size="sm">
                    {selectedWebsite}
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ maxHeight: "200px", overflowY: "auto" }}>
                    <Dropdown.Item eventKey="All">All</Dropdown.Item>
                    {websiteNameList.map((name, index) => (
                      <Dropdown.Item key={index} eventKey={name}>
                        {name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
            {/* Chart Section */}
            <div style={{ marginTop: "20px" }}>
              <ApexCharts options={chartOptions} series={chartSeries} type="line" height={350} />
            </div>
          </Card>}


          {(userRole === "ADMIN") && <Card style={{ width: "80%", padding: "20px", margin: "20px auto", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}>
            <Row className="align-items-center">
              <Col>
                <h5>Prompt Counts</h5>
              </Col>
              <Col className="text-end">
                {/* Dropdown for Date Filter */}
                <Dropdown onSelect={(key) => setDateFilterForPromptCount(key)} className="d-inline mx-2">
                  <Dropdown.Toggle variant="outline-primary" size="sm">
                    {dateFilterForPromptCount}
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ maxHeight: "200px", overflowY: "auto" }}>
                    <Dropdown.Item eventKey="Daily">Daily</Dropdown.Item>
                    <Dropdown.Item eventKey="Weekly">Weekly</Dropdown.Item>
                    <Dropdown.Item eventKey="Monthly">Monthly</Dropdown.Item>
                    <Dropdown.Item eventKey="Yearly">Yearly</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>


                {/* Dropdown for Website Selection */}
                <Dropdown onSelect={(key) => setSelectedWebsiteForPromptCount(key)} className="d-inline mx-2">
                  <Dropdown.Toggle variant="outline-secondary" size="sm">
                    {selectedWebsiteForPromptCount}
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ maxHeight: "200px", overflowY: "auto" }}>
                    <Dropdown.Item eventKey="All">All</Dropdown.Item>
                    {websiteNameList.map((name, index) => (
                      <Dropdown.Item key={index} eventKey={name}>
                        {name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
            {/* Chart Section */}
            <div style={{ marginTop: "20px" }}>
              <ApexCharts options={chartOptionsForPromptCount} series={chartSeriesForPromptCount} type="bar" height={350} />
            </div>
          </Card>}


          {(userRole === "ADMIN") &&<Card style={{ width: "80%", padding: "20px", margin: "20px auto", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}>
            <Row className="align-items-center">
              <Col>
                <h5>Top Searched Prompts</h5>
              </Col>
              <Col className="text-end">
                {/* Dropdown for Website Selection */}
                <Dropdown onSelect={(key) => setSelectedWebsiteForTopSearchedPrompts(key)} className="d-inline mx-2">
                  <Dropdown.Toggle variant="outline-secondary" size="sm">
                    {selectedWebsiteForTopSearchedPrompts}
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ maxHeight: "200px", overflowY: "auto" }}>
                    {websiteNameList.map((name, index) => (
                      <Dropdown.Item key={index} eventKey={name}>
                        {name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>


            {/* Display the Top Searched Prompts */}
            <div style={{ marginTop: "20px" }}>
              {fetchingPrompts == true ? "fetching..." : (topSearchedPrompts && topSearchedPrompts.length > 0 ? (
                <ul>
                  
                  {topSearchedPrompts.map((prompt, index) => (
                    <li key={index} style={{ marginBottom: "10px" }}>
                      {prompt}
                    </li>
                  ))}
                </ul>
              ) : (
                selectedWebsiteForTopSearchedPrompts == "Select Website" ? <p>Select website to view the top searched prompts</p> : <p>No prompts available for the selected website.</p>
              ))}
            </div>
          </Card>}


          {(userRole === "ADMIN") &&<Card style={{ width: "80%", padding: "20px", margin: "20px auto", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}>
            <Row className="align-items-center">
              <Col>
                <h5>Regional Analytics</h5>
              </Col>
              <Col className="text-end">
                {/* Dropdown for Date Filter */}
                <Dropdown onSelect={(key) => setDateFilterForRegionalAnalytics(key)} className="d-inline mx-2">
                  <Dropdown.Toggle variant="outline-primary" size="sm">
                    {dateFilterForRegionalAnalytics}
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ maxHeight: "200px", overflowY: "auto" }}>
                    <Dropdown.Item eventKey="Daily">Daily</Dropdown.Item>
                    <Dropdown.Item eventKey="Weekly">Weekly</Dropdown.Item>
                    <Dropdown.Item eventKey="Monthly">Monthly</Dropdown.Item>
                    <Dropdown.Item eventKey="Yearly">Yearly</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
            <div style={{ marginTop: "20px" }}>
              <ApexCharts options={regionalChartOptions} series={regionalChartSeries} type="bar" height={350} />
            </div>
          </Card>}
          




          <Col md="12" lg="8">
            <Row>
              {/* <Col md="12">
                <div className="card" data-aos="fade-up" data-aos-delay="800">
                  <div className="flex-wrap card-header d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">$855.8K</h4>
                      <p className="mb-0">Gross Sales</p>
                    </div>
                    <div className="d-flex align-items-center align-self-center">
                      <div className="d-flex align-items-center text-primary">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                        <div className="ms-2">
                          <span className="text-gray">Sales</span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center ms-3 text-info">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                        <div className="ms-2">
                          <span className="text-gray">Cost</span>
                        </div>
                      </div>
                    </div>
                    <Dropdown>
                      <Dropdown.Toggle
                        as={Button}
                        variant="text-gray"
                        type="button"
                        id="dropdownMenuButtonSM"
                      >
                        This Week
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#" onClick={() => handleTimePeriodChange('This Week')}>This Week</Dropdown.Item>
                        <Dropdown.Item href="#" onClick={() => handleTimePeriodChange('This Month')}>This Month</Dropdown.Item>
                        <Dropdown.Item href="#" onClick={() => handleTimePeriodChange('This Year')}>This Year</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="card-body">
                    <Chart
                      options={chart1.options}
                      series={chart1.series}
                      type="area"
                      height="245"
                    />
                  </div>
                </div>
              </Col> */}
              {/* <Col md="12" xl="6">
              <div className="card" data-aos="fade-up" data-aos-delay="900">
                <div className="flex-wrap card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Earnings</h4>
                  </div>
                  <Dropdown>
                    <Dropdown.Toggle
                      as={Button}
                      variant="text-gray"
                      type="button"
                      id="dropdownMenuButtonSM"
                    >
                      This Week
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#">This Week</Dropdown.Item>
                      <Dropdown.Item href="#">This Month</Dropdown.Item>
                      <Dropdown.Item href="#">This Year</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="card-body">
                  <div className="flex-wrap d-flex align-items-center justify-content-between">
                    <Chart
                      className="col-md-8 col-lg-8"
                      options={chart2.options}
                      series={chart2.series}
                      type="radialBar"
                      height="250"
                    />
                    <div className="d-grid gap col-md-4 col-lg-4">
                      <div className="d-flex align-items-start">
                        <svg
                          className="mt-2"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          viewBox="0 0 24 24"
                          fill="#3a57e8"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="#3a57e8"
                            ></circle>
                          </g>
                        </svg>
                        <div className="ms-3">
                          <span className="text-gray">Fashion</span>
                          <h6>251K</h6>
                        </div>
                      </div>
                      <div className="d-flex align-items-start">
                        <svg
                          className="mt-2"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          viewBox="0 0 24 24"
                          fill="#4bc7d2"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="#4bc7d2"
                            ></circle>
                          </g>
                        </svg>
                        <div className="ms-3">
                          <span className="text-gray">Accessories</span>
                          <h6>176K</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col> */}
              {/* <Col md="12" xl="6">
              <div className="card" data-aos="fade-up" data-aos-delay="1000">
                <div className="flex-wrap card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Conversions</h4>
                  </div>
                  <Dropdown>
                    <Dropdown.Toggle
                      as={Button}
                      variant="text-gray"
                      type="button"
                      id="dropdownMenuButtonSM"
                    >
                      This Week
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#">This Week</Dropdown.Item>
                      <Dropdown.Item href="#">This Month</Dropdown.Item>
                      <Dropdown.Item href="#">This Year</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="card-body">
                  <Chart
                    className="d-activity"
                    options={chart3.options}
                    series={chart3.series}
                    type="bar"
                    height="230"
                  />
                </div>
              </div>
            </Col> */}
              {/* <Col md="12" lg="12">
              <div
                className="overflow-hidden card"
                data-aos="fade-up"
                data-aos-delay="600"
              >
                <div className="flex-wrap card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="mb-2 card-title">Enterprise Clients</h4>
                    <p className="mb-0">
                      <svg
                        className="me-2"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#3a57e8"
                          d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
                        />
                      </svg>
                      15 new acquired this month
                    </p>
                  </div>
                </div>
                <div className="p-0 card-body">
                  <div className="mt-4 table-responsive">
                    <table
                      id="basic-table"
                      className="table mb-0 table-striped"
                      role="grid"
                    >
                      <thead>
                        <tr>
                          <th>COMPANIES</th>
                          <th>CONTACTS</th>
                          <th>ORDER</th>
                          <th>COMPLETION</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <img
                                className="rounded bg-soft-primary img-fluid avatar-40 me-3"
                                src={shapes1}
                                alt="profile"
                              />
                              <h6>Addidis Sportwear</h6>
                            </div>
                          </td>
                          <td>
                            <div className="iq-media-group iq-media-group-1">
                              <Link to="#" className="iq-media-1">
                                <div className="icon iq-icon-box-3 rounded-pill">
                                  SP
                                </div>
                              </Link>
                              <Link to="#" className="iq-media-1">
                                <div className="icon iq-icon-box-3 rounded-pill">
                                  PP
                                </div>
                              </Link>
                              <Link to="#" className="iq-media-1">
                                <div className="icon iq-icon-box-3 rounded-pill">
                                  MM
                                </div>
                              </Link>
                            </div>
                          </td>
                          <td>$14,000</td>
                          <td>
                            <div className="mb-2 d-flex align-items-center">
                              <h6>60%</h6>
                            </div>
                            <Progress
                              softcolors="primary"
                              color="primary"
                              className="shadow-none w-100"
                              value={60}
                              minvalue={0}
                              maxvalue={100}
                              style={{ height: "4px" }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <img
                                className="rounded bg-soft-primary img-fluid avatar-40 me-3"
                                src={shapes5}
                                alt="profile"
                              />
                              <h6>Netflixer Platforms</h6>
                            </div>
                          </td>
                          <td>
                            <div className="iq-media-group iq-media-group-1">
                              <Link to="#" className="iq-media-1">
                                <div className="icon iq-icon-box-3 rounded-pill">
                                  SP
                                </div>
                              </Link>
                              <Link to="#" className="iq-media-1">
                                <div className="icon iq-icon-box-3 rounded-pill">
                                  PP
                                </div>
                              </Link>
                            </div>
                          </td>
                          <td>$30,000</td>
                          <td>
                            <div className="mb-2 d-flex align-items-center">
                              <h6>25%</h6>
                            </div>
                            <Progress
                              softcolors="primary"
                              color="primary"
                              className="shadow-none w-100"
                              value={25}
                              minvalue={0}
                              maxvalue={100}
                              style={{ height: "4px" }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <img
                                className="rounded bg-soft-primary img-fluid avatar-40 me-3"
                                src={shapes2}
                                alt="profile"
                              />
                              <h6>Shopifi Stores</h6>
                            </div>
                          </td>
                          <td>
                            <div className="iq-media-group iq-media-group-1">
                              <Link to="#" className="iq-media-1">
                                <div className="icon iq-icon-box-3 rounded-pill">
                                  PP
                                </div>
                              </Link>
                              <Link to="#" className="iq-media-1">
                                <div className="icon iq-icon-box-3 rounded-pill">
                                  TP
                                </div>
                              </Link>
                            </div>
                          </td>
                          <td>$8,500</td>
                          <td>
                            <div className="mb-2 d-flex align-items-center">
                              <h6>100%</h6>
                            </div>
                            <Progress
                              softcolors="success"
                              color="success"
                              className="shadow-none w-100"
                              value={100}
                              minvalue={0}
                              maxvalue={100}
                              style={{ height: "4px" }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <img
                                className="rounded bg-soft-primary img-fluid avatar-40 me-3"
                                src={shapes3}
                                alt="profile"
                              />
                              <h6>Bootstrap Technologies</h6>
                            </div>
                          </td>
                          <td>
                            <div className="iq-media-group iq-media-group-1">
                              <Link to="#" className="iq-media-1">
                                <div className="icon iq-icon-box-3 rounded-pill">
                                  SP
                                </div>
                              </Link>
                              <Link to="#" className="iq-media-1">
                                <div className="icon iq-icon-box-3 rounded-pill">
                                  PP
                                </div>
                              </Link>
                              <Link to="#" className="iq-media-1">
                                <div className="icon iq-icon-box-3 rounded-pill">
                                  MM
                                </div>
                              </Link>
                              <Link to="#" className="iq-media-1">
                                <div className="icon iq-icon-box-3 rounded-pill">
                                  TP
                                </div>
                              </Link>
                            </div>
                          </td>
                          <td>$20,500</td>
                          <td>
                            <div className="mb-2 d-flex align-items-center">
                              <h6>100%</h6>
                            </div>
                            <Progress
                              softcolors="success"
                              color="success"
                              className="shadow-none w-100"
                              value={100}
                              minvalue={0}
                              maxvalue={100}
                              style={{ height: "4px" }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <img
                                className="rounded bg-soft-primary img-fluid avatar-40 me-3"
                                src={shapes4}
                                alt="profile"
                              />
                              <h6>Community First</h6>
                            </div>
                          </td>
                          <td>
                            <div className="iq-media-group iq-media-group-1">
                              <Link to="#" className="iq-media-1">
                                <div className="icon iq-icon-box-3 rounded-pill">
                                  MM
                                </div>
                              </Link>
                            </div>
                          </td>
                          <td>$9,800</td>
                          <td>
                            <div className="mb-2 d-flex align-items-center">
                              <h6>75%</h6>
                            </div>
                            <Progress
                              softcolors="primary"
                              color="primary"
                              className="shadow-none w-100"
                              value={75}
                              minvalue={0}
                              maxvalue={100}
                              style={{ height: "4px" }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Col> */}
            </Row>
          </Col>
          {/* <Col md="12" lg="4">
          <Row>
            <Col md="12" lg="12">
              <div
                className="card credit-card-widget"
                data-aos="fade-up"
                data-aos-delay="700"
              >
                <div className="pb-4 border-0 card-header">
                  <div className="p-4 border border-white rounded primary-gradient-card">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h5 className="font-weight-bold">VISA </h5>
                        <p className="mb-0">PREMIUM ACCOUNT</p>
                      </div>
                      <div className="master-card-content">
                        <svg
                          className="master-card-1"
                          width="60"
                          height="60"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#ffffff"
                            d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
                          />
                        </svg>
                        <svg
                          className="master-card-2"
                          width="60"
                          height="60"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#ffffff"
                            d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="my-4">
                      <div className="card-number">
                        <span className="fs-5 me-2">5789</span>
                        <span className="fs-5 me-2">****</span>
                        <span className="fs-5 me-2">****</span>
                        <span className="fs-5">2847</span>
                      </div>
                    </div>
                    <div className="mb-2 d-flex align-items-center justify-content-between">
                      <p className="mb-0">Card holder</p>
                      <p className="mb-0">Expire Date</p>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <h6>Mike Smith</h6>
                      <h6 className="ms-5">06/11</h6>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="flex-wrap mb-4 d-flex align-itmes-center">
                    <div className="d-flex align-itmes-center me-0 me-md-4">
                      <div>
                        <div className="p-3 mb-2 rounded bg-soft-primary">
                          <svg
                            width="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M16.9303 7C16.9621 6.92913 16.977 6.85189 16.9739 6.77432H17C16.8882 4.10591 14.6849 2 12.0049 2C9.325 2 7.12172 4.10591 7.00989 6.77432C6.9967 6.84898 6.9967 6.92535 7.00989 7H6.93171C5.65022 7 4.28034 7.84597 3.88264 10.1201L3.1049 16.3147C2.46858 20.8629 4.81062 22 7.86853 22H16.1585C19.2075 22 21.4789 20.3535 20.9133 16.3147L20.1444 10.1201C19.676 7.90964 18.3503 7 17.0865 7H16.9303ZM15.4932 7C15.4654 6.92794 15.4506 6.85153 15.4497 6.77432C15.4497 4.85682 13.8899 3.30238 11.9657 3.30238C10.0416 3.30238 8.48184 4.85682 8.48184 6.77432C8.49502 6.84898 8.49502 6.92535 8.48184 7H15.4932ZM9.097 12.1486C8.60889 12.1486 8.21321 11.7413 8.21321 11.2389C8.21321 10.7366 8.60889 10.3293 9.097 10.3293C9.5851 10.3293 9.98079 10.7366 9.98079 11.2389C9.98079 11.7413 9.5851 12.1486 9.097 12.1486ZM14.002 11.2389C14.002 11.7413 14.3977 12.1486 14.8858 12.1486C15.3739 12.1486 15.7696 11.7413 15.7696 11.2389C15.7696 10.7366 15.3739 10.3293 14.8858 10.3293C14.3977 10.3293 14.002 10.7366 14.002 11.2389Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <div className="ms-3">
                        <h5>1153</h5>
                        <small className="mb-0">Products</small>
                      </div>
                    </div>
                    <div className="d-flex align-itmes-center">
                      <div>
                        <div className="p-3 mb-2 rounded bg-soft-info">
                          <svg
                            width="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M14.1213 11.2331H16.8891C17.3088 11.2331 17.6386 10.8861 17.6386 10.4677C17.6386 10.0391 17.3088 9.70236 16.8891 9.70236H14.1213C13.7016 9.70236 13.3719 10.0391 13.3719 10.4677C13.3719 10.8861 13.7016 11.2331 14.1213 11.2331ZM20.1766 5.92749C20.7861 5.92749 21.1858 6.1418 21.5855 6.61123C21.9852 7.08067 22.0551 7.7542 21.9652 8.36549L21.0159 15.06C20.8361 16.3469 19.7569 17.2949 18.4879 17.2949H7.58639C6.25742 17.2949 5.15828 16.255 5.04837 14.908L4.12908 3.7834L2.62026 3.51807C2.22057 3.44664 1.94079 3.04864 2.01073 2.64043C2.08068 2.22305 2.47038 1.94649 2.88006 2.00874L5.2632 2.3751C5.60293 2.43735 5.85274 2.72207 5.88272 3.06905L6.07257 5.35499C6.10254 5.68257 6.36234 5.92749 6.68209 5.92749H20.1766ZM7.42631 18.9079C6.58697 18.9079 5.9075 19.6018 5.9075 20.459C5.9075 21.3061 6.58697 22 7.42631 22C8.25567 22 8.93514 21.3061 8.93514 20.459C8.93514 19.6018 8.25567 18.9079 7.42631 18.9079ZM18.6676 18.9079C17.8282 18.9079 17.1487 19.6018 17.1487 20.459C17.1487 21.3061 17.8282 22 18.6676 22C19.4969 22 20.1764 21.3061 20.1764 20.459C20.1764 19.6018 19.4969 18.9079 18.6676 18.9079Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <div className="ms-3">
                        <h5>81K</h5>
                        <small className="mb-0">Order Served</small>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="flex-wrap d-flex justify-content-between">
                      <h2 className="mb-2">$405,012,300</h2>
                      <div>
                        <span className="badge bg-success rounded-pill">
                          YoY 24%
                        </span>
                      </div>
                    </div>
                    <p className="text-info">Life time sales</p>
                  </div>
                  <div className="grid-cols-2 d-grid gap">
                    <button className="btn btn-primary text-uppercase">
                      SUMMARY
                    </button>
                    <button className="btn btn-info text-uppercase">
                      ANALYTICS
                    </button>
                  </div>
                </div>
              </div>
              <div className="card" data-aos="fade-up" data-aos-delay="500">
                <div className="text-center card-body d-flex justify-content-around">
                  <div>
                    <h2 className="mb-2">
                      750<small>K</small>
                    </h2>
                    <p className="mb-0 text-gray">Website Visitors</p>
                  </div>
                  <hr className="hr-vertial" />
                  <div>
                    <h2 className="mb-2">7,500</h2>
                    <p className="mb-0 text-gray">New Customers</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="12">
              <div className="card" data-aos="fade-up" data-aos-delay="600">
                <div className="flex-wrap card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="mb-2 card-title">Activity overview</h4>
                    <p className="mb-0">
                      <svg
                        className="me-2"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#17904b"
                          d="M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z"
                        />
                      </svg>
                      16% this month
                    </p>
                  </div>
                </div>
                <div className="card-body">
                  <div className="mb-2 d-flex profile-media align-items-top">
                    <div className="mt-1 profile-dots-pills border-primary"></div>
                    <div className="ms-4">
                      <h6 className="mb-1 ">$2400, Purchase</h6>
                      <span className="mb-0">11 JUL 8:10 PM</span>
                    </div>
                  </div>
                  <div className="mb-2 d-flex profile-media align-items-top">
                    <div className="mt-1 profile-dots-pills border-primary"></div>
                    <div className="ms-4">
                      <h6 className="mb-1 ">New order #8744152</h6>
                      <span className="mb-0">11 JUL 11 PM</span>
                    </div>
                  </div>
                  <div className="mb-2 d-flex profile-media align-items-top">
                    <div className="mt-1 profile-dots-pills border-primary"></div>
                    <div className="ms-4">
                      <h6 className="mb-1 ">Affiliate Payout</h6>
                      <span className="mb-0">11 JUL 7:64 PM</span>
                    </div>
                  </div>
                  <div className="mb-2 d-flex profile-media align-items-top">
                    <div className="mt-1 profile-dots-pills border-primary"></div>
                    <div className="ms-4">
                      <h6 className="mb-1 ">New user added</h6>
                      <span className="mb-0">11 JUL 1:21 AM</span>
                    </div>
                  </div>
                  <div className="mb-1 d-flex profile-media align-items-top">
                    <div className="mt-1 profile-dots-pills border-primary"></div>
                    <div className="ms-4">
                      <h6 className="mb-1 ">Product added</h6>
                      <span className="mb-0">11 JUL 4:50 AM</span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col> */}
        </Row>
      )}
    </Fragment>
  );
})


export default Index










