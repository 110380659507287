import { Modal, Button } from "react-bootstrap";

const DeleteConfirmation = ({ showModal, hideModal, confirmModal, id, type, message, proceedDelete }) => {
    return (
        <Modal show={showModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body><div className="alert alert-warn">{message}</div></Modal.Body>
        <Modal.Footer>
          {!proceedDelete ? 
            <Button variant="secondary" onClick={hideModal}>
              OK
            </Button>  
          : 
            <div>
              <Button className='cancel_button' variant="secondary" onClick={hideModal}>
                Cancel
              </Button>
              <Button disabled={!proceedDelete} variant="danger" onClick={() => confirmModal(type, id) }>
            Delete
          </Button>
            </div>
          }
        </Modal.Footer>
      </Modal>
    )
}

export default DeleteConfirmation;