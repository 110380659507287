import React, { useEffect, useState } from "react";
import {Row,Col} from 'react-bootstrap'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Card from '../../../components/Card'

const ConfirmMail = () => {
   const location = useLocation();
   let history = useNavigate();
   const [email, setEmail] = useState("");


   useEffect(() => {
      if(null === location.state) {
         history('/auth/sign-in')
         return;
      }
      setEmail(location.state.email);
    }, [history, location.state]);

    return (
        <>
            <section className="login-content" style={{backgroundColor:"#e6e6e6"}}>
               <Link to="/dashboard" className="navbar-brand d-flex" style={{padding: "10px", position: "absolute"}}>
                  <svg width="88.906" height="22.281" viewBox="0 0 88.906 22.281">
                     <path id="revizelogo" className="cls-1" d="M0.93,22H5.49v-5.94a6.067,6.067,0,0,1,1.3-4.29,4.7,4.7,0,0,1,3.585-1.38h0.24V5.624a4.6,4.6,0,0,0-3.24.9,6.688,6.688,0,0,0-1.89,2.64V5.924H0.93V22Zm14.73-9.3a4.666,4.666,0,0,1,1.155-2.49,3.062,3.062,0,0,1,2.325-.93,2.99,2.99,0,0,1,2.31.945A4.443,4.443,0,0,1,22.53,12.7H15.66Zm7.74,8.865a8.092,8.092,0,0,0,2.76-2.2l-2.61-2.31a6.939,6.939,0,0,1-1.86,1.26,5.083,5.083,0,0,1-2.04.39,4.085,4.085,0,0,1-2.565-.8,3.84,3.84,0,0,1-1.365-2.355H26.91q0.03-.33.045-0.63t0.015-.57a11.548,11.548,0,0,0-.465-3.285,8.058,8.058,0,0,0-1.44-2.79A7.081,7.081,0,0,0,22.62,6.344a7.834,7.834,0,0,0-3.48-.72,7.626,7.626,0,0,0-5.73,2.46,8.237,8.237,0,0,0-1.635,2.67,8.994,8.994,0,0,0-.585,3.24v0.06a8.708,8.708,0,0,0,.63,3.375,7.906,7.906,0,0,0,1.755,2.625,7.738,7.738,0,0,0,2.67,1.71,9.164,9.164,0,0,0,3.345.6A8.561,8.561,0,0,0,23.4,21.569Zm10.35,0.555h4.14l6.33-16.2H39.48l-3.63,10.77-3.6-10.77H27.42ZM45.57,22h4.56V5.924H45.57V22ZM45.45,4.154h4.8V0.1h-4.8v4.05ZM52.38,22H66.36v-3.69H58.02l8.34-9.18V5.924H52.65v3.69h8.04l-8.31,9.18V22ZM72,12.7a4.666,4.666,0,0,1,1.155-2.49,3.062,3.062,0,0,1,2.325-.93,2.99,2.99,0,0,1,2.31.945,4.443,4.443,0,0,1,1.08,2.475H72Zm7.74,8.865a8.092,8.092,0,0,0,2.76-2.2l-2.61-2.31a6.939,6.939,0,0,1-1.86,1.26,5.083,5.083,0,0,1-2.04.39,4.085,4.085,0,0,1-2.565-.8,3.84,3.84,0,0,1-1.365-2.355h11.19q0.03-.33.045-0.63t0.015-.57a11.548,11.548,0,0,0-.465-3.285,8.058,8.058,0,0,0-1.44-2.79,7.081,7.081,0,0,0-2.445-1.935,7.834,7.834,0,0,0-3.48-.72,7.626,7.626,0,0,0-5.73,2.46,8.237,8.237,0,0,0-1.635,2.67,8.994,8.994,0,0,0-.585,3.24v0.06a8.708,8.708,0,0,0,.63,3.375,7.906,7.906,0,0,0,1.755,2.625,7.738,7.738,0,0,0,2.67,1.71,9.164,9.164,0,0,0,3.345.6A8.561,8.561,0,0,0,79.739,21.569ZM85.019,22h4.83V17.2h-4.83V22Z" transform="translate(-0.938 -0.094)"></path>
                  </svg>
               </Link>
               <Row className="m-0 align-items-center bg-grey vh-100" >      
                  <Col md="12">
                     <Row className="justify-content-center">
                        <Col md="4">   
                           <Card className="card-transparent shadow-none d-flex justify-content-center mb-0">
                              <Card.Body>
                                 <div style={{padding:"140px 40px 40px 40px"}}>                                    
                                    <h3 className="mb-2 text-left">Success!</h3>
                                    <p className="text-left" style={{fontSize: 18}}>A email has been send to {email}. Please check for an
                                       email from company and click
                                       on the included link to reset your password.</p>
                                    <div className="d-inline-block w-100">
                                       <Link to="/dashboard" className="btn btn-primary mt-3">Back to Home</Link>
                                    </div>
                                 </div>
                              </Card.Body>
                           </Card>
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </section>
        </>
    )
}

export default ConfirmMail;
