import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { error } from "../../../notify";


const ErrorLogs = () => {
   const [errorLogs, setErrorLogs] = useState([]);
   const [selectedLog, setSelectedLog] = useState(null);
   const [showModal, setShowModal] = useState(false);
   const [itemsPerPage, setItemsPerPage] = useState(50);
   const [currentPage, setCurrentPage] = useState(1);
   const [currentRangeStart, setCurrentRangeStart] = useState(1);
   const pagesToShow = 10;
   const offset = useRef(0);
   const [siteDataLength, setSiteDataLength] = useState(0)
   const [selectedFilter, setSelectedFilter] = useState("All");
   const [uniqueWebsites, setUniqueWebsites] = useState([]);
   const [uniqueWebsitesWithIds, setUniqueWebsitesWithIds] = useState([]);
   const [isPageLimitFetched, setIsPageLimitFetched] = useState(false);
   const isUniqueWebsitesFetched = useRef(false);
   const token = sessionStorage.getItem("revizeTrainingToken");
   const errorLogsApi = `${process.env.REACT_APP_SERVER_URL}/error_logs`;
   const getWebsiteNameApi = `${process.env.REACT_APP_SERVER_URL}/fetch_website_names`
   const getPageLimitApi = `${process.env.REACT_APP_SERVER_URL}/api/get_page_limit/`;
   const logs_count_url = `${process.env.REACT_APP_SERVER_URL}/error_logs_count`


   const headers = {
       accept: "application/json",
       Authorization: `Bearer ${token}`,
   };




   useEffect(() => {
       fetchCount(selectedFilter); // Call this once when the component mounts
   }, []); // Empty dependency array ensures this runs only once


  


   useEffect(() => {
       if (isPageLimitFetched) {
           const offset = (currentPage - 1) * itemsPerPage;
           setErrorLogs([]); // Clear previous logs
           fetchErrorLogs(offset,selectedFilter == "All" ? "All" : uniqueWebsitesWithIds[selectedFilter]); // Fetch logs with the updated offset
       }
   }, [currentPage, isPageLimitFetched]);


   const fetchCount = async (selectedFilter) => {
       const headers = { "Content-Type": "application/json", "Authorization": "Bearer " + token };
       let url = logs_count_url;
       try {
           const response = await axios.get(url, {
               headers,
               params: { selectedFilter } // Pass as query parameter
           });
           setSiteDataLength(response.data)
           console.log("selectedfiler inside fetchCount",selectedFilter)
           console.log("response.data inside fetchCount",response.data)
           // return response.data; // The count of total documents


       } catch (err) {
           console.error('Error fetching count:', err);
           throw err;
       }
   };


   // Fetch the page limit when the component mounts
   useEffect(() => {
      
       const fetchPageLimit = async () => {
           const loggedInUserId = sessionStorage.getItem("loggedInUserId");
           if (!loggedInUserId) return;


           try {
               const response = await axios.get(getPageLimitApi, {
                   headers: headers,
                   params: { user_id: loggedInUserId },
               });


               const pageLimitData = response.data;
               setItemsPerPage(pageLimitData.page_limit);
               setIsPageLimitFetched(true);
           } catch (error) {
               if (error.response) {
                   console.error("Error fetching page limit:", error.response.data.detail);
               } else {
                   console.error("Error fetching page limit:", error);
               }
           }
       };


       if (sessionStorage.getItem("current_page_limit") == null) {
           fetchPageLimit();
       }else{
           setItemsPerPage(sessionStorage.getItem("current_page_limit"))
           setIsPageLimitFetched(true);
       }
   }, []); // Empty dependency array ensures this runs only once


   const fetchWebsiteNames = async () => {
       try {
           const response = await axios.get(getWebsiteNameApi, {
               headers: headers,
           });
  
           const websiteData = response.data; // Assuming API returns a dictionary with names as keys
           const websiteNames = Object.keys(websiteData); // Extract only the keys (website names)
  
           setUniqueWebsitesWithIds(websiteData)
           setUniqueWebsites(["All", ...websiteNames]); // Add "All" at the beginning of the list
           isUniqueWebsitesFetched.current = true; // Mark as fetched
       } catch (error) {
           console.error("Error fetching website names:", error);
       }
   };   
  
   // Fetch error logs based on offset
   const fetchErrorLogs = async (offset,selectedFilter) => {
       console.log("selected_filter inside fetchErrorLogs",typeof selectedFilter)
       const skip = offset;
       try {
           const response = await axios.get(errorLogsApi, {
               headers: headers,
               params: { skip: skip, limit: itemsPerPage,selectedFilter:selectedFilter },
           });


           const logsData = response.data;
           setErrorLogs(logsData);


           if (!isUniqueWebsitesFetched.current){
               await fetchWebsiteNames();
           }
       } catch (error) {
           console.error("Error fetching error logs:", error);
       }
   };


   const truncateText = (text, maxLength = 100) => {
       if (!text) return "N/A";
       return text.length > maxLength
           ? `${text.substring(0, maxLength)}...`
           : text;
   };


   const openFullMessageModal = (log) => {
       setSelectedLog(log);
       setShowModal(true);
   };


   const handleCloseModal = () => {
       setShowModal(false);
       setSelectedLog(null);
   };




   let totalPages = Math.ceil(siteDataLength / itemsPerPage);




   const paginate = (pageNumber) => {
       setCurrentPage(pageNumber);
       const newOffset = (pageNumber - 1) * itemsPerPage;
       offset.current = newOffset; // Update the offset ref
       // fetchErrorLogs(newOffset,selectedFilter); // Pass the correct offset to fetchErrorLogs
   };


   // Handlers for pagination range navigation
   const handleNextRange = () => {
       if (currentRangeStart + pagesToShow <= totalPages) {
           setCurrentRangeStart(currentRangeStart + pagesToShow);
       }
   };


   const handlePrevRange = () => {
       if (currentRangeStart - pagesToShow > 0) {
           setCurrentRangeStart(currentRangeStart - pagesToShow);
       }
   };


   // Page numbers based on current range
   const visiblePageNumbers = Array.from(
       { length: Math.min(pagesToShow, totalPages - currentRangeStart + 1) },
       (_, index) => currentRangeStart + index
   );


   // useEffect(() => {
   //     fetchCount()
   //     const offset = (currentPage - 1) * itemsPerPage;
   //     fetchErrorLogs(offset);
   // }, [currentPage, itemsPerPage]);


   const userApi = `${process.env.REACT_APP_SERVER_URL}/api/users/me`;


   useEffect(() => {
       if (!token) {
           window.location.href = '/auth/sign-in';
       }
       if (token) {
           // eslint-disable-next-line
           const requestOptions = {
               method: "GET",
               headers: { "accept": "application/json", "Authorization": "Bearer " + token },
           };


           fetch(userApi, requestOptions)
               .then(function (response) {
                   return response.json();
               }).then(function (data) {
                   if (data.is_active == false) {
                       sessionStorage.removeItem("revizeTrainingToken");
                       sessionStorage.removeItem("loggedInUserId");
                       sessionStorage.removeItem("userRole");
                       sessionStorage.removeItem("firstName");
                       window.location.href = '/auth/sign-in';
                   }
               }).catch(err => {
                   console.log("error", err);


                   // Clear session storage and redirect to sign-in
                   sessionStorage.removeItem("revizeTrainingToken");
                   sessionStorage.removeItem("loggedInUserId");
                   sessionStorage.removeItem("userRole");
                   sessionStorage.removeItem("firstName");
                   window.location.href = '/auth/sign-in';
               });
       }


   }, [])


   const handleFilterChange = (value) => {
       setSelectedFilter(value);
       setCurrentPage(1);
       setCurrentRangeStart(1);
       fetchCount(value);
       totalPages = Math.ceil(siteDataLength / itemsPerPage);
       setErrorLogs([]);
       offset.current = 0
       fetchErrorLogs(offset.current,value == "All" ? "All" : uniqueWebsitesWithIds[value]);
       console.log("total_pages ",totalPages)
   };


   return (
       <div style={{ overflowX: 'auto' }}>


           <div style={{ marginBottom: "10px", marginTop: "50px",marginRight:"50px", display: "flex", justifyContent: "flex-end" , alignItems: "center"}}>
               <label className='dropdown-label' style={{ marginRight: "10px" }}>Filter</label>
               <select
                   className="dropdown-button"
                   value={selectedFilter}
                   onChange={(e) => handleFilterChange(e.target.value)}
               >
                   {uniqueWebsites.map((website, index) => (
                       <option key={index} className="dropdown-option" value={website}>
                           {website}
                       </option>
                   ))}
               </select>
           </div>


           <table className="table table-hover">
               <thead>
                   <tr>
                       <th>Client</th>
                       <th>Document</th>
                       <th>Error Area</th>
                       <th>Error Message</th>
                       <th>Creation Timestamp</th>
                   </tr>
               </thead>
               <tbody>
                   {errorLogs.map((log) => (
                       <tr key={log.reference_id}>
                           <td>{log.website_name}</td>
                           <td>{log.doc_name.length > 40 ? log.doc_name.substring(0, 40) + "..." : log.doc_name}</td>
                           <td>{log.error_area}</td>
                           <td>
                               <span
                                   onClick={() => openFullMessageModal(log)}
                                   style={{ cursor: 'pointer', textDecoration: 'underline' }}
                               >
                                   {log.error_message?.length > 40 ? log.error_message.substring(0, 40) + "..." : log.error_message}
                               </span>
                           </td>
                           <td>{new Date(log.creation_timestamp).toLocaleString()}</td>
                       </tr>
                   ))}
               </tbody>
           </table>


           <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
               <div style={{ display: 'flex', gap: '5px' }}>
                   {/* Left arrow */}
                   {currentRangeStart > 1 && (
                       <button
                           onClick={handlePrevRange}
                           style={{
                               width: '35px',
                               height: '35px',
                               backgroundColor: '#f0f0f0',
                               border: '1px solid #ddd',
                               borderRadius: '50%',
                               cursor: 'pointer',
                               display: 'flex',
                               justifyContent: 'center',
                               alignItems: 'center',
                           }}
                       >
                           &lt;
                       </button>
                   )}


                   {/* Page numbers */}
                   {visiblePageNumbers.map((number) => (
                       <button
                           key={number}
                           onClick={() => paginate(number)}
                           style={{
                               width: '35px',
                               height: '35px',
                               backgroundColor: currentPage === number ? '#007bff' : '#f0f0f0',
                               color: currentPage === number ? 'white' : 'black',
                               border: currentPage === number ? '1px solid #007bff' : '1px solid #ddd',
                               borderRadius: '50%',
                               cursor: 'pointer',
                               display: 'flex',
                               justifyContent: 'center',
                               alignItems: 'center',
                           }}
                       >
                           {number}
                       </button>
                   ))}


                   {/* Ellipsis if there are more pages */}
                   {currentRangeStart + pagesToShow <= totalPages && (
                       <span style={{ width: '30px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'default' }}>...</span>
                   )}


                   {/* Right arrow */}
                   {currentRangeStart + pagesToShow <= totalPages && (
                       <button
                           onClick={handleNextRange}
                           style={{
                               width: '35px',
                               height: '35px',
                               backgroundColor: '#f0f0f0',
                               border: '1px solid #ddd',
                               borderRadius: '50%',
                               cursor: 'pointer',
                               display: 'flex',
                               justifyContent: 'center',
                               alignItems: 'center',
                           }}
                       >
                           &gt;
                       </button>
                   )}


               </div>
           </div>


           {selectedLog && (
               <Modal show={showModal} onHide={handleCloseModal} size="xl">
                   <Modal.Header closeButton>
                       <Modal.Title>Full Error Details</Modal.Title>
                   </Modal.Header>
                   <Modal.Body>


                       {selectedLog.error_message && (
                           <div className="mt-3">
                               <h5>Error Message:</h5>
                               <pre style={{
                                   backgroundColor: '#f4f4f4',
                                   padding: '10px',
                                   borderRadius: '5px',
                                   maxHeight: '300px',
                                   overflowY: 'auto'
                               }}>
                                   <td>{selectedLog.error_message}</td>
                               </pre>
                           </div>
                       )}


                       {selectedLog.stack_trace && (
                           <div className="mt-3">
                               <h5>Stacktrace:</h5>
                               <pre style={{
                                   backgroundColor: '#f4f4f4',
                                   padding: '10px',
                                   borderRadius: '5px',
                                   maxHeight: '300px',
                                   overflowY: 'auto'
                               }}>
                                   {selectedLog.stack_trace}
                               </pre>
                           </div>
                       )}
                   </Modal.Body>
                   <Modal.Footer>
                       <Button variant="secondary" onClick={handleCloseModal}>
                           Close
                       </Button>
                   </Modal.Footer>
               </Modal>
           )}
       </div>
   );
};


export default ErrorLogs;




