import { Modal, Button } from "react-bootstrap";

const RetrainConfirmation = ({ showModal, hideModal, confirmModal, id, type, message, proceedTrain }) => {
    return (
        <Modal show={showModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Retrain Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body><div className="alert alert-warn">{message}</div></Modal.Body>
        <Modal.Footer>
          {!proceedTrain ? 
            <Button variant="secondary" onClick={hideModal}>
              OK
            </Button>  
          : 
            <div>
              <Button className='cancel_button' variant="secondary" onClick={hideModal}>
                Cancel
              </Button>
              <Button disabled={!proceedTrain} variant="primary" onClick={() => confirmModal(type, id) }>
                Retrain
              </Button>
            </div>
          }
        </Modal.Footer>
      </Modal>
    )
}

export default RetrainConfirmation;