import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Button, Col, Modal, Row, Form, Checkbox } from "react-bootstrap";
import Card from "../../../components/Card";
import { error, success } from "../../../notify";
import axios from 'axios';

const ExternalWebsites = () => {
  const [websites, setWebsites] = useState([]);
  const [currentWebsite, setCurrentWebsite] = useState("");
  const [currentWebsiteId,setCurrentWebsiteId] = useState(0)
  const [websiteToEdit, setWebsiteToEdit] = useState(null);
  const [isCrawlingEnabled, setIsCrawlingEnabled] = useState(false); // New state for crawling checkbox

  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const location = useLocation();
  const { siteId } = location.state || {};
  const addApiUrl = `${process.env.REACT_APP_SERVER_URL}/api/website/allowed_external_websites`;
  const fetchWebsitesApiUrl = `${process.env.REACT_APP_SERVER_URL}/api/website/allowed_external_websites`;
  const editWebsiteApiUrl = `${process.env.REACT_APP_SERVER_URL}/api/website/allowed_external_websites`; // Add the URL for fetching websites
  const deleteWebsiteApiUrl = `${process.env.REACT_APP_SERVER_URL}/api/website/allowed_external_websites`;

  useEffect(() => {
    // Fetch the websites when the component mounts
    const fetchWebsites = async () => {
      try {
        const response = await axios.get(fetchWebsitesApiUrl, {
          params: { website_id: siteId }, 
        });
        setWebsites(response.data.websites);
      } catch (err) {
        error(err.response?.data?.message || "Failed to fetch websites.");
      }
    };

    fetchWebsites();
  }, [siteId]); // Dependency array ensures this effect runs when siteId changes

  const handleCloseAddEditModal = () => {
    setCurrentWebsite("");
    setWebsiteToEdit(null);
    setShowAddEditModal(false);
  };

  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleAddWebsite = async () => {
    if (currentWebsite.trim() !== "") {
      try {
        const payload = {
          url: currentWebsite.trim(),
          website_id: siteId, // Change websiteId to website_id
          enable_crawling: isCrawlingEnabled, // Change enableCrawling to enable_crawling
        };
  
        const response = await axios.post(addApiUrl, payload);
        console.log(websites)
        console.log("response.data",response.data)
        // Assuming the API returns the added website's data in the response
        setWebsites([...websites, response.data]);
        success("Website added successfully.");
        handleCloseAddEditModal();
      } catch (err) {
        error(err.response?.data?.message || "Failed to add website.");
      }
    }
  };

  const handleEditWebsite = async () => {
    if (currentWebsite.trim() !== "" && websiteToEdit !== null) {

      try {
        const payload = {
            external_link: currentWebsite.trim(),
            crawlable: isCrawlingEnabled,
          };
  
        const response = await axios.put(editWebsiteApiUrl+"/"+currentWebsiteId, payload);
        console.log("response.data",response.data)
  
        // Update the local state with the updated website data
        const updatedWebsites = websites.map((website, index) =>
          index === websiteToEdit
            ? {
                ...website,
                external_link: currentWebsite.trim(),
                enable_crawling: isCrawlingEnabled,
              }
            : website
        );
  
        setWebsites(updatedWebsites);
        success("Website updated successfully.");
        handleCloseAddEditModal();
      } catch (err) {
        error(err.response?.data?.message || "Failed to edit website.");
      }
    }
  };
  

  const handleDeleteWebsite = async () => {
    if (websiteToEdit !== null) {
      try {
        const websiteId = websites[websiteToEdit].external_link_id;
        const deleteUrl = `${deleteWebsiteApiUrl}/${websiteId}`;
        await axios.delete(deleteUrl); // Send delete request to API
  
        // Update the state to remove the deleted website
        setWebsites(websites.filter((_, index) => index !== websiteToEdit));
        success("Website deleted successfully.");
      } catch (err) {
        error(err.response?.data?.message || "Failed to delete website.");
      } finally {
        handleCloseDeleteModal(); // Close the modal
      }
    }
  };
  

  const handleShowAddModal = () => {
    setCurrentWebsite("");
    setIsCrawlingEnabled(false);
    setShowAddEditModal(true);
  };

  const handleShowEditModal = (index) => {
    setCurrentWebsiteId(websites[index].external_link_id)
    setCurrentWebsite(websites[index].external_link);
    setIsCrawlingEnabled(websites[index].crawlable || false);
    setWebsiteToEdit(index);
    setShowAddEditModal(true);
  };

  const handleShowDeleteModal = (index) => {
    setWebsiteToEdit(index);
    setShowDeleteModal(true);
  };

  return (
    <Row>
      <Col sm="12">
        <Card>
          <Card.Header className="d-flex justify-content-between flex-wrap">
            <h4 className="card-title mb-0">External Websites</h4>
            <div className="d-flex align-items-center">
              <Button onClick={handleShowAddModal} className="btn-primary">
                Add Website
              </Button>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="table-responsive">
              <table className="table-hover table-striped table">
                <thead>
                  <tr>
                    <th className="text-center">Website</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {websites.map((website, index) => (
                    <tr key={index}>
                      <td>{website.external_link}</td>
                      <td className="text-center">
                        {/* Edit Button */}
                        <Button
                          variant="link"
                          className="btn-sm btn-icon text-primary"
                          onClick={() => handleShowEditModal(index)}
                        >
                          <svg
                            width="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.7476 20.4428H21.0002"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12.78 3.79479C13.5557 2.86779 14.95 2.73186 15.8962 3.49173C15.9485 3.53296 17.6295 4.83879 17.6295 4.83879C18.669 5.46719 18.992 6.80311 18.3494 7.82259C18.3153 7.87718 8.81195 19.7645 8.81195 19.7645C8.49578 20.1589 8.01583 20.3918 7.50291 20.3973L3.86353 20.443L3.04353 16.9723C2.92866 16.4843 3.04353 15.9718 3.3597 15.5773L12.78 3.79479Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M11.021 6.00098L16.4732 10.1881"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </Button>

                        {/* Delete Button */}
                        <Button
                          variant="link"
                          className="btn-sm btn-icon text-danger"
                          onClick={() => handleShowDeleteModal(index)}
                        >
                          <svg
                            width="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            stroke="currentColor"
                          >
                            <path
                              d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M20.708 6.23975H3.75"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
      </Col>

      {/* Add/Edit Modal */}
      <Modal show={showAddEditModal} onHide={handleCloseAddEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>{websiteToEdit !== null ? "Edit Website" : "Add Website"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Website URL</Form.Label>
              <Form.Control
                type="url"
                placeholder="Enter website URL"
                value={currentWebsite}
                onChange={(e) => setCurrentWebsite(e.target.value)}
              />
            </Form.Group>
            <Form.Check
              type="checkbox"
              label="Enable Crawling"
              checked={isCrawlingEnabled}
              onChange={() => setIsCrawlingEnabled(!isCrawlingEnabled)}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddEditModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={websiteToEdit !== null ? handleEditWebsite : handleAddWebsite}
          >
            {websiteToEdit !== null ? "Save Changes" : "Add Website"}
          </Button>
        </Modal.Footer>
      </Modal>
    
    {/* Delete Confirmation Modal */}
    <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this website?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteWebsite}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      
    </Row>
  );
};

export default ExternalWebsites;
